// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.1.43',
  apiURL: 'https://dev1.rockwell-logic.ie/api',
  ga_measurement_id: '',
  cryptoKey: '6360abf9187a95952b67870a128d91df9c84797843f2569376738eb49135de0392c1971d2e9d911d5aad3064585fad31d9c11d175364de34740d7aebc7de85019e3441f997b7071ccc6e75b8ec02f0b2e153f5683e93b564daa358a163f6662cbf45cb92',
  platform: 'web-admin',
  clientPortalURL: 'https://dev1client.rockwell-logic.ie'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
