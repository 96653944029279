import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../pages/login/auth.service';


@Injectable({
  providedIn: 'root'
})
export class DepartmentGuard {
  constructor(private authService: AuthService, private router: Router) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const excludedDepartments = route.data['excludedDepartments']; // Excluded department values attached to route
    const userDepartment = await this.authService.getAdminDepartment(); // Get the admin's department from the service

    if (excludedDepartments.includes(userDepartment.value)) {
      this.router.navigate(['/dashboard']); // Redirect to default page
      return false; // Deny access
    } else {
      return true; // Allow access
    }
  }
}