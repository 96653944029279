import { Injectable } from '@angular/core';
import { GetOptions, GetResult, Preferences, RemoveOptions, SetOptions } from '@capacitor/preferences';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
import { AuthService } from '../pages/login/auth.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SecureStorageService {

  constructor(
    private router: Router
  ) { }

  secureSet(options: SetOptions): Promise<void> {
    // Data to be stored
    const dataToEncrypt = options.value;

    // Secret key for encryption
    const secretKey = environment.cryptoKey;

    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(dataToEncrypt, secretKey).toString();

    options.value = encryptedData;

    return Preferences.set(options);
  }

  async secureGet(options: GetOptions): Promise<GetResult> {
    const encryptedData = (await Preferences.get(options)).value;

    if (encryptedData) {
      // Secret key for decryption (must be the same key used for encryption)
      const secretKey = environment.cryptoKey;
    
      // Decrypt the data
      const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    
      try {
        // Convert the decrypted bytes to a string
        const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
        console.log('Decrypted Data:', decryptedData);

        return Promise.resolve({value: decryptedData});

      } catch (error) {
        console.log(error)
        Preferences.clear();
        this.router.navigateByUrl('/login', { replaceUrl: true });
        throw new Error('storage problem')
      }
    
      
    } else {
      return Promise.resolve({value: null});
    }


  }

  remove(options: RemoveOptions): Promise<void> {
    return Preferences.remove(options);
  }

  secureAndReturn(stringToEncrypt: string): string {
    // Secret key for encryption
    const secretKey = environment.cryptoKey;
    // Encrypt the data
    const encryptedData = CryptoJS.AES.encrypt(stringToEncrypt, secretKey).toString();
    return encryptedData;
  }
  decryptAndReturn(encryptedString: string): string {
    // Secret key for encryption
    const secretKey = environment.cryptoKey;
    // Decrypt the data
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
    // Convert the decrypted bytes to a string
    const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
    return decryptedData;
  }
}
